import ModelHub from "~/components/valuationModels/ModelHub.vue";
export default {
  __name: 'index',
  setup: function setup(__props) {
    var _useNuxtApp = useNuxtApp(),
      $featureToggle = _useNuxtApp.$featureToggle;
    var showValuationModel = computed(function () {
      var _$featureToggle$toggl;
      return (_$featureToggle$toggl = $featureToggle.toggles) === null || _$featureToggle$toggl === void 0 ? void 0 : _$featureToggle$toggl["pe-valuation-model"];
    });
    return {
      __sfc: true,
      $featureToggle: $featureToggle,
      showValuationModel: showValuationModel,
      ModelHub: ModelHub
    };
  }
};